<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Registro de ventas" :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true" :tooltip="`El resumen funciona sólo para documentos aceptados en ${$t('generals.SII')}. No están considerados aquellos DTEs que estén pendientes de envío o rechazados.`" :scroll="scroll">
      <template v-slot:main>
        <!-- <v-btn class="mr-2" @click="dialogExport = true" outlined :disabled="count === 0"><v-icon left size="20">mdi-export-variant</v-icon>Exportar</v-btn> -->
        <v-col style="max-width: 160px;" class="pa-0">
          <v-menu v-model="menuStartDay" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="280" z-index="1000">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="date.start" v-bind="attrs" v-on="on" prefix="desde" append-icon="mdi-calendar-month" outlined single-line dense readonly hide-details />
            </template>
            <v-date-picker v-model="date.start" @input="menuStartDay=false" no-title :first-day-of-week="1" type="date" locale="es" width="270" color="primary">
              <v-row class="mb-1 px-6" align="center" justify="end">
                <v-btn class="body-1" :ripple="false" text color="primary" @click="() => { date.start=null }">Limpiar</v-btn>
              </v-row>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col style="max-width: 160px;" class="ml-2 pr-0">
          <v-menu v-model="menuEndDay" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="280" z-index="1000">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field class="pa-0" v-model="date.end" v-bind="attrs" v-on="on" prefix="hasta" append-icon="mdi-calendar-month" outlined single-line dense readonly hide-details />
            </template>
            <v-date-picker v-model="date.end" @input="menuEndDay=false" no-title type="date" locale="es" width="270" color="primary" :open-date="date.start" :min="date.start" :max="endDate">
              <v-row class="mb-1 px-6" align="center" justify="end">
                <v-btn class="body-1" :ripple="false" text color="primary" @click="() => { date.end=null }">Limpiar</v-btn>
              </v-row>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-btn @click="setQuery('filter'), getDocumentsList()" color="primary" class="ml-2">Actualizar</v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col cols="12" class="px-0">
      <skeleton-list v-if="loading" />
      <template v-else>
        <v-data-table
          :class="{'fixed-table' : $store.state.base.isExpandNavigationDrawer}"
          :headers="headersList"
          :items="list"
          hide-default-header
          disable-sort
          mobile-breakpoint
          hide-default-footer
          :items-per-page="pagination"
          no-data-text="No existen datos"
          style="cursor: pointer"
          @click:row="(list) => { $router.push({name: 'DocumentsList', params: $route.params, query: {document_type: idDocumentType(list.document_type), status__code: 8, date_gte: $route.query.start, date__lte: $route.query.end}}).catch(() => {}) }"
        >
          <!-- header -->
          <template v-slot:header="{ props: { headers } }">
            <VTableHeaders :headers="headers" @sort="setSort" />
            <v-divider style="position: absolute;  margin-top: -6px; margin-left: -5px; min-width: 100%" />
          </template>
          <!-- end header -->
        </v-data-table>
      </template>
    </v-col>
  </v-row>
</template>
  <script>
  import moment from 'moment'
  import orderBy from 'lodash/orderBy'
  import ListViewMixin from '@/mixins/ListViewMixin'
  import MiniHeader from '@/components/commons/MiniHeader'
  import GenericViewMixin from '@/mixins/GenericViewMixin'
  import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'

  export default {
    components: {
      SkeletonList,
      MiniHeader,
      VQueryBuilder: () => import('@/components/VQueryBuilder/VQueryBuilder'),
      VTableHeaders: () => import('@/components/VTable/VTableHeaders')
    },
    mixins: [
      GenericViewMixin,
      ListViewMixin
    ],
    data: () => ({
      scroll: 0,
      date: {
        start: null,
        end: null
      },
      menuStartDay: false,
      menuEndDay: false,
      loading: false,
      headers: [],
      filters: [
        {
          type: 'date',
          id: 'start',
          id2: 'end',
          label: 'Fecha',
          value: null,
          value2: null,
          isSimpleOperators: true,
          operator: 'rango',
          countries: ['PE', 'CL']
        }
      ],
      translate: {
        'document type': {
          is: 'document_type',
          text: 'Tipo de documento',
          type: 'document_type',
          sortable: true
        },
        document_type: {
          text: 'Tipo de documento',
          type: 'document_type',
          sortable: true
        },
        count: {
          text: 'Total de doc.',
          align: 'end',
          type: 'numeric',
          sortable: true
        },
        exempt: {
          text: 'Monto exento',
          align: 'end',
          sortable: true
        },
        net: {
          text: 'Monto neto',
          align: 'end',
          sortable: true
        },
        tax: {
          text: 'Monto IVA',
          align: 'end',
          sortable: true
        },
        total: {
          text: 'Monto total',
          align: 'end',
          sortable: true
        }
      },
      documentsList: [],
      breadcrumbs: {
        main: 'Reporte',
        children: [
          {
            text: 'Registro de ventas'
          }
        ]
      }
    }),
    computed: {
      headersList () {
        let list = []
        this.headers.forEach((h) => {
          if (this.translate[h]) {
            list.push({...this.translate[h], value: this.translate[h].is ?? h})
          }
        })
        return list
      },
      list () {
        if (this.$route.query.ordering) return this.sortBy()
        return this.documentsList
      },
      endDate () {
        return this.date.start ? moment(this.date.start).endOf('month').format('YYYY-MM-DD') : null
      }
    },
    watch: {
      $route: {
        handler (val) {
          if (!Object.keys(val.query).includes('ordering')) {
            this.date = {
              start: val.query.start,
              end: val.query.end
            }
          }
        },
        inmediate: false,
        deep: false
      },
      'date.start' () {
        this.date.end = null
      }
    },
    created () {
      if (!Object.keys(this.$route.query).length) this.setQuery()

      this.date = {
        start: this.$route.query.start,
        end: this.$route.query.end
      }
      this.getDocumentsList()
    },
    methods: {
      onScroll (e) {
        this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
      },
      setQuery (isFilter = false) {
        let startDay = isFilter ? this.date.start : moment().startOf('month').format('YYYY-MM-DD')
        let endDay = isFilter ? this.date.end : moment().endOf('month').format('YYYY-MM-DD')

        this.$router.replace({ params: this.$route.params, query: { start: startDay, end: endDay} }).catch(err => err)
      },
      getDocumentsList () {
        let query = JSON.parse(JSON.stringify(this.$route.query))
        if (Object.keys(this.$route.query).includes('ordering')) {
          delete query.ordering
        }
        this.loading = true
        this.$store.dispatch('documents/LIST', {
          resource: 'metrics/document_summary/table',
          query
        })
        .then((response) => {
          this.headers = [...response.data.labels]
          this.documentsList = JSON.parse(JSON.stringify(response.data.datasets))
          this.headersList.forEach((item) => {
            this.documentsList.forEach((resp, i) => {
              if (typeof resp[item.value] === 'number' && item.type !== 'numeric') {
                this.documentsList[i][item.value] = `${this.formatNumber2(resp[item.value])} ${this.firstDocumentsTypeCurrency(response.data.datasets[i].document_type)}`
              }
              if (item.type === 'document_type') {
                this.documentsList[i][item.value] = this.nameDocumentsType(resp[item.value])
              }
            })
          })
        })
        .finally(() => {
          this.loading = false
        })
      },
      setSort ($event) {
        if (this.$route.query.ordering === $event) {
          $event = `-${$event}`
        }
        this.$router.replace({params: this.$route.params, name: this.$route.name, query: Object.assign({}, this.$route.query, {ordering: $event})}).catch(err => err)
      },
      sortBy () {
        const type = !this.$route.query.ordering.search('-')
        const value = type ? this.$route.query.ordering.slice(1) : this.$route.query.ordering
        return orderBy(this.documentsList, [value], [!type ? 'asc' : 'desc'])
      }
    }
  }
</script>